
import { Options, Vue } from 'vue-class-component'
import axios from '@/plugins/axios'
import { ElMessage } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import { ElFormItem } from 'element-plus'

interface Res {
  list: []
  total: number
}

interface Result {
  total: number
  data: []
  message: string
  status: boolean
}
@Options({
  components: {}
})
export default class CronManagerPage extends Vue {
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  listCron = [
    {
      account: {
        _id: '',
        name: '',
        connectionData: []
      },
      children: [{}],
      elements: [],
      user: {
        email: ''
      },
      connectFrom: {
        _id: ''
      },
      connectTo: {
        _id: ''
      },
      expiried: false
    }
  ]
  rules = {
    user: [
      {
        required: true,
        message: 'Please input user',
        trigger: 'blur'
      }
    ]
  }
  listId = ['']
  listAccount = []
  listConnection = [
    {
      isActive: Boolean,
      _id: '',
      connections: [{ connection: '', from: true }]
    }
  ]
  listExpiryDate = [{}]
  listConnectionExpiryDate = [{ connection: '', from: true }]
  listConnectionExpiryId = [{}]
  condition = {
    user: []
  }
  refForm = ''
  activeName = ''
  page = 1
  pageSize = 20
  grouped = {}
  grouped2 = {}
  dataCopy = { listScriptCustom: [] }
  disableUserDialog = false
  enableUserDialog = false
  disableItem: any = ''
  disableList: any = []
  enableItem: any = ''
  enableList: any = []

  async created() {
    await this.getListCron()
    await this.getListAccount()
  }
  async getListCron(page = 0) {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Res = {
      list: [],
      total: 0
    }
    res = await axios.get('user/setting-script/list-all', {
      headers: this.headers,
      params: {
        account: localStorage.getItem('account'),
        page: page
      }
    })
    if (res) {
      this.listCron = res.list
      this.listCron.forEach((element: any) => {
        if (element.account != null) {
          this.listConnection.push(...element.account.connectionData)
        }
      })
      this.listConnection.shift()
      this.listConnection.forEach((connection) => {
        if (!connection.isActive) {
          this.listConnectionExpiryDate.push(...connection.connections)
        }
      })
      this.listConnectionExpiryDate.shift()
      this.listConnectionExpiryDate.forEach((connect) => {
        if (connect.from == false) {
          this.listConnectionExpiryId.push(connect.connection)
        }
      })
      this.listConnectionExpiryId.shift()
      const uniqueSet = new Set(this.listConnectionExpiryId)
      const backToArray = [...uniqueSet]
      backToArray.forEach((id) => {
        this.listCron.forEach((cron) => {
          if (id === cron.connectFrom?._id || id === cron.connectTo?._id) {
            cron.expiried = true
          }
        })
      })

      this.grouped = this.listCron.reduce(function (r, a) {
        if (a.user != null) {
          r[a.user.email] = r[a.user.email] || []
          r[a.user.email].push(a)
        }
        return r
      }, Object.create(null))
    }

    await this.$store.dispatch('setLoading', false, { root: true })
  }
  clickDisable(item: any, list: any) {
    // await ElMessageBox.confirm(
    //   'Do you really want to disable this user ?',
    //   'Warning',
    //   {
    //     confirmButtonText: 'OK',
    //     cancelButtonText: 'Cancel',
    //     type: 'warning'
    //   }
    // )
    //   .then(() => {
    //     this.handleDisable(item, list)
    //     this.getListCron()
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })

    this.disableUserDialog = true
    this.disableItem = item
    this.disableList = list
  }

  clickEnable(item: any, list: any) {
    // await ElMessageBox.confirm(
    //   'Do you really want to enable this user ?',
    //   'Warning',
    //   {
    //     confirmButtonText: 'OK',
    //     cancelButtonText: 'Cancel',
    //     type: 'warning'
    //   }
    // )
    //   .then(async () => {
    //     this.handleEnable(item, list)
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })
    // await this.getListCron()
    this.enableUserDialog = true
    this.enableItem = item
    this.enableList = list
  }

  handleEnable() {
    this.enableUser(this.enableItem, this.enableList)
  }

  handleDisable() {
    this.disableUser(this.disableItem, this.disableList)
    this.getListCron()
  }

  async enableUser(
    item: any,
    list: [{ account: { _id: '' }; status: boolean; _id: '' }]
  ) {
    let form = {
      id: '',
      disableByAdmin: false,
      status: true
    }
    form.id = item._id
    list.forEach((e) => {
      if (e.status) {
        this.listId.push(e._id)
      }
    })
    this.listId.splice(0, 1)
    let res: Result
    await this.$store.dispatch('setLoading', true, { root: true })

    await axios.put('admin/user', form, {
      headers: {
        'token-user': localStorage.getItem('tokenUser')
      }
    })

    res = await axios.put('user/setting-script/enable-all-cron', this.listId, {
      params: {
        account: list[0].account._id
      }
    })
    if (res.status) {
      this.$message.success('Successfully !!!')

      this.enableUserDialog = false
      this.enableItem = ''
      this.enableList = []
    } else this.$message.error('Something wrong !!!')
    this.listId = ['']
    this.getListCron()
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  async disableUser(
    item: any,
    list: [{ account: { _id: '' }; status: boolean; _id: '' }]
  ) {
    let form = {
      id: '',
      disableByAdmin: true,
      status: true
    }
    form.id = item._id
    let res: Result
    list.forEach((e) => {
      if (e.status) {
        this.listId.push(e._id)
      }
    })
    this.listId.splice(0, 1)
    await this.$store.dispatch('setLoading', true, { root: true })
    await axios.put('admin/user', form, {
      headers: {
        'token-user': localStorage.getItem('tokenUser')
      }
    })

    await axios.put('user/setting-script/update-many', list, {
      headers: this.headers,
      params: {
        account: localStorage.getItem('account')
      }
    })

    res = await axios.put('user/setting-script/disable-all-cron', this.listId, {
      params: {
        account: list[0].account._id
      }
    })
    if (res.status) {
      this.$message.success('Successfully !!!')

      this.disableUserDialog = false
      this.disableItem = ''
      this.disableList = []
    } else this.$message.error('Something wrong !!!')
    this.listId = ['']
    this.getListCron()
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  handleDisableUserCancel() {
    this.disableUserDialog = false
    this.disableItem = ''
    this.disableList = []
  }
  handleEnableUserCancel() {
    this.enableUserDialog = false
    this.enableItem = ''
    this.enableList = []
  }

  async updateStatus(item: any) {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Result
    await axios.put('user/setting-script/update-status', item, {
      headers: this.headers,
      params: {
        account: localStorage.getItem('account')
      }
    })
    res = await axios.post(
      `user/setting-script/toggleCronStatus/${item._id}`,
      {},
      {
        params: {
          account: item.account._id
        }
      }
    )
    if (res.status) {
      this.$message.success(res.message)
    } else {
      this.$message.error(res.message)
      item.status = !item.status
    }
    await this.getListCron()
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  async clickRun(item: any) {
    item.disable = false
    await ElMessageBox.confirm('Are you sure to run this cron?', 'Warning', {
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      type: 'warning'
    })
      .then(() => {
        this.updateStatus(item)
      })
      .catch((error) => {
        item.disable = true
        console.log(error)
      })
  }

  async clickStop(item: any) {
    item.disable = true
    await ElMessageBox.confirm(
      'Are you sure to stop and disable this cron?',
      'Warning',
      {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }
    )
      .then(() => {
        this.updateStatus(item)
      })
      .catch((error) => {
        item.disable = false
        console.log(error)
      })
  }
  async clickDelete(item: any) {
    await ElMessageBox.confirm('Do you really want to delete ?', 'Warning', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning'
    })
      .then(() => {
        this.onDelete(item, this.headers)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  async onDelete(item: any, headers: any) {
    var id = item._id
    item.listScriptCustom.forEach((i: any) => {
      i.deleted = true
      i.status = false
    })
    this.dataCopy = { ...item }
    await this.$store.dispatch('setLoading', true, { root: true })
    await axios
      .delete('user/setting-script', {
        headers: { headers, id }
      })
      .then()
      .catch(function (error) {
        console.log(error)
      })
    await axios
      .put('user/script-custom/update-many', this.dataCopy.listScriptCustom, {
        headers: this.headers,
        params: {
          account: localStorage.getItem('account')
        }
      })
      .then(function (response) {
        if (response.status) {
          ElMessage.success('Delete successfully !!!')
        } else {
          ElMessage.error('Something wrong !!!')
        }
      })
      .catch(function (error) {
        ElMessage.error('Something wrong !!!')
        console.log(error)
      })
    await this.getListCron()
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  async getListAccount() {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Result
    res = await axios.get('admin/user/get-all', {
      headers: this.headers
    })
    if (res) {
      this.listAccount = res.data
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  async resultQuery(page = 0) {
    this.refForm = 'formFilter'
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement
    const isValidate = await refFrorm.validate()
    if (!isValidate) return
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Res = {
      list: [],
      total: 0
    }
    res = await axios.get('user/setting-script/list-all', {
      headers: this.headers,
      params: {
        account: localStorage.getItem('account'),
        page: page,
        condition: this.condition
      }
    })
    if (res) {
      this.listCron = res.list
      this.grouped = this.listCron.reduce(function (r, a) {
        r[a.user.email] = r[a.user.email] || []
        r[a.user.email].push(a)
        return r
      }, Object.create(null))
      console.log(this.listCron)
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  clearQuery() {
    this.condition = { user: [] }
    this.getListCron()
  }
  handleCurrentChange(index = 1) {
    this.page = index
    this.getListCron(index)
  }
}
